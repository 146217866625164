<template>
  <div>
    <h3 ref="def">
      What is Stress?
    </h3>
    <p>
      Have you ever wondered why is it so easy to poke a hole in a piece of paper with the tip (front-end) of a pen
      but not so much with the back-end of the pen?
      <br> <br>
      The answer lies in the notion of stress. Stress describes how much force a body experiences per unit area, and is an important notion in mecahnics of material.
      Stress depends upon not only the magnitude of applied force but also the area onto which
      the force is applied. Thus, the same magnitude of force applied over different areas can result in different values of stress. <br> <br>
      For example &mdash; when you poke a piece of paper with the tip of a pen, the entire applied force is concentrated over the tiny area of the tip. As a result, the paper experiences a large stress &mdash; making it easier to poke a hole in it.
      On the other hand, when you poke a piece of paper with the back-end of the pen, the applied force is spread over a much larger area, resulting in a much lower stress on the paper, and as a result, poking a hole is not so easy that way.
      <br>
    </p>
    <h3 ref="type">
      Types of Stress: Normal vs. Shear Stress
    </h3>
    <p>There are two types of stress in mechanics.</p>
    <ul face="square">
      <li>
        <h5>Normal Stress </h5>
        The stresses caused due to forces that act normal to the surface are called normal stresses. It is often denoted as \( \sigma \).
      </li>
      <li>
        <h5> Shear Stress </h5>
        The stressed caused due to forces that act tangential to the surface are called shear stresses. Shear stress is often denoted as \(\tau\).
      </li>
    </ul>
    <br>
    <h3 ref="calculation">
      Calculating Stress
    </h3>
    <p>
      Let's consider a sqaure bar with a uniform cross-section area \(A \) as shown below. The bar is subjected to a pair of equal  forces with magnitude \(F\) &mdash; both acting along its axis but in opposite directions. Next, consider a cut perpendicular to the length of the bar.  Upon force balance, we obtain the internal force acting on the cross-section as \(F\).
    </p>
    <v-layout justify-center>
      <v-img contain
             src="/assets/stress.png"
             max-height="350px"
             max-width="350px"
             width="400px"
      />
    </v-layout>
    <p>
      Thus, the stress acting on that cross-sectional area of the bar can be obtained as:
      $$\sigma = \frac{F}{A}$$
      This exercise can be repeated for any cut through the bar, and it can be shown that the stress remains uniform throughout the length of the bar (given that the cross-sectional area of the bar remains constant).
    </p>
    <h3 ref="playgraph">
      MagicGraph: Stress in a Non-uniform Bar
    </h3>
    <p>
      Through this MagicGraph, we will explain the notion of stress by means of a simple illustration.
      In this illustration, a cylindrical bar with cross-sectional area varying along its length is subjected to equal but opposite forces
      at its ends, as a result of which, a tensile stress is developed.
      Because the bar has different cross-sectional area at different points, it experiences varying magnitude of stress along its length - higher stresses at locations that have smaller cross-sectional area and smaller stress at locations with larger cross-sectional area. Drag point 'A' along the length to examine the stress at different points along the length of the bar. Note the locations along the bar that have maximum and minimum stresses.
    </p>
    <p>
      To get started:
      <ul style="list-style-type:square;">
        <li> Drag the pointer named 'Up&Down' to vary the cross-section geometry of the bar. </li>
        <li> Drag the pointer named 'Scan' to scan along the length of the bar. </li>
      </ul>
    </p>
    <v-responsive
      :aspect-ratio="1"
    >
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <v-hover v-slot:default="{ hover }">
          <v-btn :color="hover ? '#757575' : '#2E2E2E' "
                 large
                 depressed
                 @click="$router.push({name: 'physics'})"
          >
            <v-img
              src="/assets/masswhite.svg"
              aspect-ratio="1"
              contain
            />
          </v-btn>
        </v-hover>
        <v-hover v-slot:default="{ hover }">
          <v-btn :color="hover ? '#757575' : '#2E2E2E' "
                 large
                 depressed
                 @click="$router.push({name: 'home'})"
          >
            <v-img
              src="/assets/edliy.svg"
              aspect-ratio="1"
              width="5px"
              contain
            />
          </v-btn>
        </v-hover>
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Stress',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Stress';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is stress?', img:'/assets/number-1.svg', action: () => this.goto('def')},
      {title: 'Types of stress', img:'/assets/number-2.svg', action: () => this.goto('type')},
      {title: 'Calculation of stress', img:'/assets/number-3.svg', action: () => this.goto('calculation')},
      {title: 'Interactive Stress',img:'/assets/touch.svg', action: () => this.goto('playgraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
    return{ title: 'Stress',
        titleTemplate: '%s - Learn interactively',
        meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                {name: 'description', content: 'Forces and stresses'}
              ]
      }
 },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
