const Boxes = {
  box1: function () {
	JXG.Options.board.minimizeReflow = 'none';
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-9, 13, 15,-10],keepaspectratio: true, axis:false, ticks:false, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
	brd1.options.layer['image']=15;
	brd1.options.layer['polygon']=1;
	brd1.options.layer['intersection']=5;
	var resize = function () {
            brd.resizeContainer(brd1.containerObj.clientWidth, brd.containerObj.clientHeight, true);
            brd.fullUpdate();
            };
    window.onresize = resize;
	brd1.create('text', [4, 11, '<b>Stress in a Bar under Tension</b>'], {fixed:true, fontSize:function(){return 28*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	brd1.create('axis', [[-3, 0], [1, 0]]);
	brd1.create('axis', [[-3, 0], [-3, 1]]);
	var shft=5;
	var line1 = brd1.create('line', [[0,-0.5-shft], [0, -1.5-shft]], {visible: false, straightFirst: false, straightLast: false});
	var Pts = [];
	Pts[1] = brd1.create('point', [-3.0, -1.5-shft], {name: '', size:2, face:'square', fixed:true, visible:false});
	Pts[2] = brd1.create('glider', [0, -0.8-shft, line1], {name: 'Up&Down', size:2, face:'square', color:'black'});
	Pts[3] = brd1.create('point', [3.0, -1.5-shft], {name: '', size:2, face:'square', fixed:true, visible:false});
	var c = brd1.create('spline',Pts,{strokeWidth:1, strokeColor:'black'});
	var refl = brd1.create('segment', [[-2.975,-shft],[+2.975,-shft]],{visible:false});
	var cref =brd1.create('reflection',[c, refl],{strokeWidth:1, strokeColor:'black'});
	var hbase1=brd1.create('polygon',[[-3, -1.5-shft],[-5,-1.5-shft],[-5, 1.5-shft],[-3, 1.5-shft]], {fillColor:'grey', withLines:true, vertices:{visible:false, fixed:true}, borders:{strokeColor:'black'}});
	var hbase2=brd1.create('polygon',[[3, -1.5-shft],[5,-1.5-shft],[5, 1.5-shft],[3, 1.5-shft]], {fillColor:'grey', withLines:true, vertices:{visible:false, fixed:true}, borders:{strokeColor:'black'}});
	brd1.create('image', ['/assets/front.svg', [5, -1-shft],[2,2]],{fixed:true});
	brd1.create('image', ['/assets/back.svg', [-7, -1-shft],[2,2]],{fixed:true});
	brd1.create('image', ['/assets/sort.svg', [function(){return Pts[2].X()-0.25}, function(){return Pts[2].Y()-0.3}],[0.5, 0.6]]);
	brd1.create('functiongraph',[function(x){return 10/Math.PI/((Pts[1].Y()-Pts[2].Y())*x*x/9+Pts[2].Y()+shft)/((Pts[1].Y()-Pts[2].Y())*x*x/9+Pts[2].Y()+shft)}, -3, 3],{strokeColor:'black', strokeWidth:4} );
	brd1.create('segment', [[-3,-3.2],[-3,+9]],{strokeWidth:1, strokeColor:'grey', fixed:true});
	brd1.create('segment', [[3,-3.2],[3,+9]],{strokeWidth:1, strokeColor:'grey', fixed:true});
	var glid = brd1.create('glider', [-1.5, 1.-shft, refl], {name: 'Scan', size:2, face:'square', color:'black'});
	brd1.create('image', ['/assets/sort.svg', [function(){return glid.X()+0.3}, function(){return glid.Y()-0.25}],[0.5, 0.6]],{rotate:90});
	brd1.create('point', [function(){return glid.X()}, function(){return 10/Math.PI/((Pts[1].Y()-Pts[2].Y())*glid.X()*glid.X()/9+Pts[2].Y()+shft)/((Pts[1].Y()-Pts[2].Y())*glid.X()*glid.X()/9+Pts[2].Y()+shft)}], {size:2, name:'&sigma;_{SS\'}'})
	var linn =brd1.create('line', [glid, [function(){return glid.X()}, -1 ]], {strokeColor:'red', strokeWidth:1, dash:1});
	brd1.create('intersection', [linn, c], {size:2, color:'black', name:'S\'', face:'square'});
	brd1.create('intersection', [linn, cref], {size:2, color:'black', name:'S', face:'square'})
	brd1.create('circle', [[10.0, -shft],[function(){return ((Pts[1].Y()-Pts[2].Y())*glid.X()*glid.X()/9+Pts[2].Y())+10+shft},-shft]],{fillColor:'lightblue', strokeColor:'black'});
	brd1.create('text', [9, -1, 'Section SS\''], {fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	brd1.create('text', [9, 0.5, 'x'], {fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	brd1.create('text', [-2.75, 11, '<b>&sigma;</b>(x)'], {fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	brd1.create('text', [7, -4.9, '10 N'], {fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	brd1.create('text', [-8.5, -4.9, '10 N'], {fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    },
}
export default Boxes;
